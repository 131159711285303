<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script>

import * as cookies from "js-cookie";
import axios from 'axios';
import config from '@/config';
import "vue-toastification/dist/index.css";

export default {
	beforeMount() {
		if (cookies.get("kyano_session")) {
			let token = cookies.get('kyano_session')
			axios.get(`${config.url.api}/v1/users/0/info`, { headers: { authorization: `user ${token}` } }).then((res) => {
				this.$store.dispatch('settoken', cookies.get('kyano_session'))
				this.$store.dispatch('setuserdata', res.data)
			})
			axios.get(`${config.url.api}/v1/orders/shop/`, { headers: { authorization: `user ${token}` } }).then((res) => {
				this.$store.dispatch('setshops', res.data)
				if (!sessionStorage.getItem('selectedshop')) {
					sessionStorage.setItem('selectedshop', 0)
				}
			})
		} else {
			location.replace(`${config.url.login}/login?r=${encodeURI(location.href)}`)
		}
	}
}
</script>

<style>
@font-face {
  /* light */
  font-family: "Cerebri Sans";
  src: url("https://cdn.kyano.app/font/cerebri-sans/light.woff2")
    format("woff2");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  /* regular */
  font-family: "Cerebri Sans";
  src: url("https://cdn.kyano.app/font/cerebri-sans/regular.woff2")
    format("woff2");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  /* bold */
  font-family: "Cerebri Sans";
  src: url("https://cdn.kyano.app/font/cerebri-sans/bold.woff2")
    format("woff2");
  font-weight: 700;
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	font-family: "Cerebri Sans";
}
:root {
  --primary-color: #011a62;
  --secondary-color: #34c759;
  --systemgray2021: #FAFBFD;
  --systemgrey4: #d1d1d6;
  --systemgrey5: #e5e5ea;
}
#app {
	font-family: "Cerebri Sans";
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #1c1c1e;
	background: #f5f5f9;
}

.text-center {
	text-align: center;
}

.m-auto {
	margin: 0 auto;
}

.text-primary {
	color: var(--primary-color);
}

.text-secondary {
	color: var(--secondary-color);
}

.p-5 {
	padding: 25px;
}

.bg-white {
	background: #fff;
}

.bg-light-btn {
	background: #f5f5f9;
	border-radius: 8px;
	padding: 10px !important;
	text-align: left;
}

.bg-light-btn img {
	text-align: left;
	margin-bottom: 10px;
}

.min-h-100vh {
	min-height: 100vh;
}

.h-100 {
	height: 100%;
}

.v-btn,
.v-tab {
	text-transform: none !important;
	font-weight: 400;
	letter-spacing: unset !important;
	font-size: 16px !important;
}

.container-90 {
	max-width: 90% !important;
}

.outlined{
border: 1px solid #d1d1d6
}

.v-navigation-drawer, .v-main{
  background-color: var(--systemgray2021)!important;
}
</style>
