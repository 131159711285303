module.exports = {
	url: {
		api: `https://api.kyano.app`,
		login: `https://account.kyano.app`,
		main: `https://kyano.app`
	},
	regex: {
		name: /^[a-zA-Z -]{0,50}$/,
		product_name: /^[a-zA-Z0-9'\-| ]{0,50}$/,
		product_subtitle: /^[a-zA-Z0-9'&?/\\ ]{0,100}$/,
		product_description: /[a-zA-Z0-9'&?.,"%^()/\\ ]{0,1000}/
	},
	rules: {
		name: [
			value => (value && !(value.length > 50)) || 'Maximum 50 characters',
			value => (/^[a-zA-Z0-9'\-| ]{0,50}$/.test(value)) || 'Invalid characters',
		],
		subtitle: [
			value => (!value || !(value.length > 100)) || 'Maximum 100 characters',
			value => (/^[a-zA-Z0-9'&?/\\ ]{0,100}$/.test(value)) || 'Invalid characters',
		],
		description: [
			value => (!value || !(value.length > 5000)) || 'Maximum 5000 characters',
			value => (/[a-zA-Z0-9'&?.,"%^()/\\ ]{0,1000}/.test(value)) || 'Invalid characters',
		],
	},
	dateformat: {
		weekday: 'short',
		year: 'numeric',
		day: 'numeric',
		month: 'long',
		hour: 'numeric',
		minute: 'numeric'
	}
}