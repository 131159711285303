import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/website',
    component: () => import(/* webpackChunkName: "home" */ '../views/Website.vue')
  },
  {
    path: '/o1',
    component: () => import(/* webpackChunkName: "about" */ '../views/Onboarding1.vue')
  },
  {
    path: '/o2',
    component: () => import(/* webpackChunkName: "about" */ '../views/Onboarding2.vue')
  },
  {
    path: '/login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/password-reset',
    component: () => import(/* webpackChunkName: "about" */ '../views/PasswordReset.vue')
  },
  {
    path: '/password-new',
    component: () => import(/* webpackChunkName: "about" */ '../views/PasswordNew.vue')
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "about" */ '../views/Dashboard.vue')
  },
  {
    path: '/orders',
    component: () => import(/* webpackChunkName: "orders" */ '../views/orders/Overview.vue')
  },
  {
    path: '/order/:id',
    component: () => import(/* webpackChunkName: "about" */ '../views/orders/__template.vue'),
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "about" */ '../views/orders/Order.vue')
      }, 
      {
        path: 'timeline',
        component: () => import(/* webpackChunkName: "about" */ '../views/orders/OrderTimeline.vue')
      },
      {
        path: 'actions',
        component: () => import(/* webpackChunkName: "about" */ '../views/orders/OrderActions.vue')
      }, 
    ]
  },
  {
    path: '/customers',
    component: () => import(/* webpackChunkName: "orders" */ '../views/customers/Overview.vue')
  },
  {
    path: '/customer/:id',
    component: () => import(/* webpackChunkName: "about" */ '../views/customers/__template.vue'),
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "about" */ '../views/customers/Customer.vue')
      }, 
      {
        path: 'products',
        component: () => import(/* webpackChunkName: "about" */ '../views/customers/Products.vue')
      },
      {
        path: 'invoices',
        component: () => import(/* webpackChunkName: "about" */ '../views/customers/Invoices.vue')
      },
      {
        path: 'timeline',
        component: () => import(/* webpackChunkName: "about" */ '../views/customers/Timeline.vue')
      },
      {
        path: 'emails',
        component: () => import(/* webpackChunkName: "about" */ '../views/customers/Emails.vue')
      },
      {
        path: 'subscriptions',
        component: () => import(/* webpackChunkName: "about" */ '../views/customers/Subscriptions.vue')
      },
    ]
  },
  {
    path: '/coupons',
    component: () => import(/* webpackChunkName: "orders" */ '../views/coupons/Overview.vue')
  },
  {
    path: '/coupon/:id',
    component: () => import(/* webpackChunkName: "about" */ '../views/coupons/__template.vue'),
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "about" */ '../views/coupons/Coupon.vue')
      }, 
      {
        path: 'usage-limit',
        component: () => import(/* webpackChunkName: "about" */ '../views/coupons/CouponUsageLimit.vue')
      }, 
      {
        path: 'usage-restriction',
        component: () => import(/* webpackChunkName: "about" */ '../views/coupons/CouponUsageRestriction.vue')
      },
    ]
  },
  {
    path: '/products',
    component: () => import(/* webpackChunkName: "orders" */ '../views/products/Overview.vue')
  },
  {
    path: '/product/:id',
    component: () => import(/* webpackChunkName: "about" */ '../views/products/__template.vue'),
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "about" */ '../views/products/Product.vue'),
      }, 
    ],
  },
  {
    path: '/subscriptions',
    component: () => import(/* webpackChunkName: "orders" */ '../views/subscriptions/Overview.vue')
  },
  {
    path: '/subscription/:id',
    component: () => import(/* webpackChunkName: "about" */ '../views/subscriptions/__template.vue'),
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "about" */ '../views/subscriptions/Subscription.vue'),
      }, 
    ],
  },
  {
    path: '/checkouts',
    component: () => import(/* webpackChunkName: "orders" */ '../views/checkouts/Overview.vue'),
    
  },
  {
    path: '/checkout/:id',
    component: () => import(/* webpackChunkName: "about" */ '../views/checkouts/__template.vue'),
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "about" */ '../views/checkouts/Checkout.vue'),
      }, 
      {
        path: 'advanced',
        component: () => import(/* webpackChunkName: "about" */ '../views/checkouts/Advanced.vue'),
      }, 
      {
        path: 'upsells',
        component: () => import(/* webpackChunkName: "about" */ '../views/checkouts/Upsells.vue'),
      },  
      {
        path: 'layout',
        component: () => import(/* webpackChunkName: "about" */ '../views/checkouts/Layouts.vue'),
      },  
      
    ] 
  },
  {
    path: '/thankyoupages',
    component: () => import(/* webpackChunkName: "orders" */ '../views/thankyoupages/Overview.vue')
  },
  {
    path: '/thankyoupage/:id',
    component: () => import(/* webpackChunkName: "about" */ '../views/thankyoupages/__template.vue'),
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "about" */ '../views/thankyoupages/Thankyoupage.vue'),
      }, 
      {
        path: 'advanced',
        component: () => import(/* webpackChunkName: "about" */ '../views/thankyoupages/Advanced.vue'),
      },       
    ] 
  },
  {
    path: '/thankyou/:id',
    component: () => import(/* webpackChunkName: "about" */ '../templates/thankyoupage/__template.vue'),
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "about" */ '../templates/thankyoupage/Template1.vue'),
      },
    ]
  },
  {
    path: '/settings',
    component: () => import(/* webpackChunkName: "orders" */ '../views/settings/__template.vue'),
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "about" */ '../views/settings/Overview.vue')
      },
      {
        path: 'general',
        component: () => import(/* webpackChunkName: "about" */ '../views/settings/General.vue'),
      },
      {
        path: 'payments',
        component: () => import(/* webpackChunkName: "about" */ '../views/settings/Payments.vue')
      },
      {
        path: 'preferences',
        component: () => import(/* webpackChunkName: "about" */ '../views/settings/Preferences.vue')
      },
      {
        path: 'widgets',
        component: () => import(/* webpackChunkName: "about" */ '../views/settings/Widgets.vue')
      },
      {
        path: 'terms',
        component: () => import(/* webpackChunkName: "about" */ '../views/settings/Terms.vue')
      },
      {
        path: 'domainname',
        component: () => import(/* webpackChunkName: "about" */ '../views/settings/Domainname.vue')
      },
      {
        path: 'emailsettings',
        component: () => import(/* webpackChunkName: "about" */ '../views/settings/Emailsettings.vue')
      },
      {
        path: 'emailtemplates',
        component: () => import(/* webpackChunkName: "about" */ '../views/settings/Emailtemplates.vue')
      },
      {
        path: 'shortcodes',
        component: () => import(/* webpackChunkName: "about" */ '../views/settings/Shortcodes.vue')
      },
      {
        path: 'webhooks',
        component: () => import(/* webpackChunkName: "about" */ '../views/settings/Webhooks.vue')
      },
      {
        path: 'analytics',
        component: () => import(/* webpackChunkName: "about" */ '../views/settings/Analytics.vue')
      },
      {
        path: 'paymentmethodes',
        component: () => import(/* webpackChunkName: "about" */ '../views/settings/Paymentmethodes.vue')
      },
      {
        path: 'system',
        component: () => import(/* webpackChunkName: "about" */ '../views/settings/System.vue')
      },
     
    ]
  },
  {
    path: '/:catchAll(.*)',
    component: () => import(/* webpackChunkName: "404" */ '../views/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
