import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		auth: {
			token: null,
		},
		user: {
			id: -1,
			firstname: "",
			insertion: null,
			lastname: "",
			email: "",
			profilepicture: false,
			staff: false
		},
		shops: [
			{
				"id": -1,
				"domain": "",
				"owner": -1,
				"name": "",
				"email": "",
				"notify_email": "",
				"address": {
					"line1": "",
					"city": "",
					"postal": "",
					"country": ""
				},
				"settings": {
					"mollietest": true
				}
			}
		],
		language: {
			orders: {
				dashboard: {
					revenue: "omzet",
				}
			},
			date: {
				today: 'vandaag',
				monday: "maandag"
			}
		}
	},
	mutations: {
		settoken(state, t) {
			state.auth.token = t
		},
		setuserdata(state, data) {
			state.user = data
		},
		setshops(state, data) {
			state.shops = data
		},
	},
	actions: {
		settoken(context, t) {
			context.commit('settoken', t)
		},
		setuserdata(context, data) {
			context.commit('setuserdata', data)
		},
		setshops(context, data) {
			context.commit('setshops', data)
		},
	},
	modules: {},
	getters: {
		getshop(state) {
			return state.shops[sessionStorage.getItem('selectedshop')];
		},
		getlanguage(state) {
			return state.language;
		},
		getuser(state) {
			return state.user;
		},
		gettoken(state) {
			return state.auth.token;
		},
	}
})