import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#011a62',
                secondary: '#34c759',
                bg_light: '#f5f5f9',
                systemwhite: '#ffffff',
                systemgrey6: '#f5f5f9',
                systemgrey5: '#e5e5ea',
                systemred: '#ff3b30',
                danger: '#ff3b30',
            },
        },
    },
});